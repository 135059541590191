
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import Image from "next/image";
import center1 from "../../public/index/center_1.png"
import center2 from "../../public/index/center_2.png"
import left1 from "../../public/index/left_1.png"
import left2 from "../../public/index/left_2.png"
import left3 from "../../public/index/left_3.png"
import left4 from "../../public/index/left_4.png"
import left5 from "../../public/index/left_5.png"
import right1 from "../../public/index/right_1.png"
import right2 from "../../public/index/right_2.png"
import right3 from "../../public/index/right_3.png"
import right4 from "../../public/index/right_4.png"
import golden from "../../public/index/golden_ticket.svg"
import money from "../../public/index/money.svg"
import Link from "next/link";
type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    return (
     <Box css={{
         paddingLeft:"$4",
         paddingRight:"$4"
     }}>
         <Flex css={{
              justifyContent:"center",
             "@md":{
                 justifyContent:"space-around"
             }
         }}>
             <Box css={{
                 display:"none",
                 "@md": {
                     display:"block",
                     width:"33%"
                 }
             }}>
                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center"
                 }}>
                     <Image src={left1} alt="" />
                 </Box>

                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center"
                 }}>
                     <Image src={left2} alt="" />
                 </Box>
                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center"
                 }}>
                     <Image src={left3} alt="" />
                 </Box>

                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center"
                 }}>
                     <Image src={left4} alt="" />
                 </Box>


                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center",
                     position:"relative"
                 }}>
                     <Image src={left5} alt="" style={{
                         transform:"scale(0.6)"
                     }}/>
                     <Text css={{
                         textAlign:"center",
                         fontWeight:"600",
                         textTransform:"uppercase",
                         position:"absolute",
                         bottom:"15%"
                     }}>
                         HUGE ANNUAL
                         Pandamonium PARTIES
                     </Text>
                 </Box>

             </Box>
             <Box css={{
                 width:"100%",
                 "@md": {
                     width:"33%"
                 }
             }}>
                 <Text style="h2" css={{
                     fontSize:"55px",
                     textTransform:"uppercase",
                     textShadow:"0 0 28px #00ffeb,0 0 9px #00ffeb",
                     display:"block",
                     textAlign:"center"

                 }}>Unlock the good life.</Text>
                 <Text css={{
                     textAlign:"center",
                     fontSize:"35px",
                     marginTop:"10px",
                     fontWeight:"600",
                     display:"block"
                 }}>
                     Perks for days. Parties for nights.
                 </Text>
                 <Text css={{
                     display:"block",
                     textAlign:"center",
                     lineHeight:"24px",
                     marginTop:"$4"
                 }}>
                     Don’t just join the club—own it. As a Panda holder, you have ownership in the most social and innovative global network of like-minded artists, creators, influencers and game-changers.
                 </Text>
                 <Text css={{
                     display:"block",
                     textAlign:"center",
                     lineHeight:"24px",
                     marginTop:"$4"
                 }}>
                     Together, we’re building an exclusive club with unbridled access to experiences you won’t find anywhere else.
                     From UFC events and sold-out concerts, to sporting events and the biggest parties at the hottest venues, we’ll
                     squeeze every last drop out of life.
                 </Text>
                 <Text css={{
                     display:"block",
                     lineHeight:"24px",
                     fontSize:"18px",
                     textAlign:"center",
                     marginTop:"$4"
                 }}>
                     <b>Welcome to Kanpai Pandas—life on a whole other level.</b>
                 </Text>

                <Box css={{
                    marginTop:"$6",
                    backgroundColor:"transparent",
                    boxShadow:"0 0 60px 10px #00ffeb",
                    borderRadius:"50%",
                }}>
                    <Image src={center1} alt="" />
                </Box>
                 <Box css={{

                     maxWidth:"100%",
                     marginTop:"$6",
                     display:"flex",
                     flexDirection:"column"
                 }}>
                     <Image src={center2} alt="" style={{
                         transform:"scale(1.5)",
                     }}/>
                     <Text css={{
                         textAlign:"center",
                         marginTop:"$5",
                         fontWeight:"600"
                     }}>
                         15-year suite at Allegiant Stadium
                     </Text>
                 </Box>

             </Box>
             <Box css={{
                 display:"none",
                 "@md": {
                     display:"block",
                     width:"33%"
                 }
             }}>
                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center"
                 }}>
                     <Image src={right1} alt="" />
                 </Box>

                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center",
                     marginTop:"$6"
                 }}>
                     <Image src={right2} alt="" />
                 </Box>
                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center",
                     marginTop:"$6"
                 }}>
                     <Image src={right3} alt="" />
                 </Box>

                 <Box css={{
                     display:"flex",
                     flexDirection:"column",
                     alignItems:"center",
                     marginTop:"$6"
                 }}>
                     <Image src={right4} alt="" />
                     <Text css={{
                         textAlign:"center",
                         fontWeight:"600",
                         textTransform:"uppercase"
                     }}>
                         INSANE GIVEAWAYS
                     </Text>
                 </Box>


             </Box>
         </Flex>
         <Box>
             <Flex align="center" justify="center" direction="column">
                 <Text css={{
                     textTransform:"uppercase",color:"#ffc300",fontSize:"25px",
                     textAlign:"center",
                     marginBottom:"$6"
                 }}>
                     IT’s LIKE A
                 </Text>
                 <Box css={{
                     position:"relative",
                     animation: "Tickets_blink__BQxDv 3s linear infinite",
                     animationDelay: "2s"
                 }}>
                     <Image src={golden} alt="" />

                     <Image src={money} alt="" style={{
                         position:"absolute",
                         left:"50%",
                         top:"50%",
                         transform: "translate(-50%, -50%)"
                     }}/>
                 </Box>
             </Flex>

         </Box>
             <Flex align="center" justify="center">
                 <Text css={{
                     fontSize:"30px",textTransform:"uppercase",color:"#fff",margin:"0 15px",textShadow:"0 0 7px #ffc300,0 0 6px #ffc300",
                     fontWeight:"600",
                     textAlign:"center",
                     marginTop:"$6"
                 }}>Own a panda. Join the party.</Text>
             </Flex>

         <Flex align="center" justify="center" css={{
             marginTop:"$4",
             marginBottom:"$4"
         }}>
             <Box css={{

                 border:" 4px solid #FFC300",
                 textShadow: "0px 0px 3px #FFC300, 0px 0px 2px #FFC300",
                 paddingLeft:"$6",
                 paddingRight:"$6",
                 paddingTop:"$4",
                 paddingBottom:"$4",
                 cursor:"pointer",
                 fontSize:"30px",
                 fontWeight:"600"

             }}>
                 <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}  >BUY ONE</Link>
             </Box>
         </Flex>
     </Box>
    )
}

type TrendingCollectionsSchema =
paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
ssr: {
    trendingMints: TrendingMintsSchema
    trendingCollections: TrendingCollectionsSchema
    featuredCollections: TrendingCollectionsSchema
}
}> = async ({ params, res }) => {
const chainPrefix = params?.chain || ''
const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
// init BaseApi
// supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
// DefaultChain
// console.log("请求的路劲",reservoirBaseUrl)
const headers: RequestInit = {
    headers: {
        'x-api-key': process.env.RESERVOIR_API_KEY || '',
    },
}
let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'volume',
    }

const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
)
let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'sales',
    }

const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
)

let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        type: 'any',
    }

const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
)

const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
]).catch((e) => {
    console.error(e)
})

const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
        ? (promises[0].value.data as Props['ssr']['trendingCollections'])
        : {}
const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['featuredCollections'])
        : {}

const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['trendingMints'])
        : {}

res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
)
return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
}
}
export default NFTBanner